import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Script } from "gatsby";
import Header from "../components/header";
import Footer from "../components/footer";
import Background from "../components/background";
import Loader from "../components/loader";
import Notice from "../components/notice";
import MissingPage from "../components/404";
import * as styles from "./index.module.scss";

// markup
const Layout = ({ children, location }) => {
  const locationPath = location.pathname.split("/")[1];
  const fullPath = location.pathname;
  const stores = ["liverpool", "leeds"];

  const contains = stores.some((element) => {
    if (fullPath.indexOf(element) !== -1) {
      return true;
    }

    return false;
  });

  const wrapperRef = useRef();
  const [firstLoad, setFirstLoad] = useState(true);
  const [windowHeight, setWindowHeight] = useState();

  if (firstLoad === true) {
    setTimeout(function () {
      setFirstLoad(false);
    }, 5000);
  }

  function appHeight() {
    setWindowHeight(window.innerHeight);
    document.documentElement.style.setProperty(
      "--app-height",
      `${windowHeight}px`
    );
  }

  useEffect(() => {
    appHeight();
    window.addEventListener("resize", appHeight);
    return (_) => {
      window.removeEventListener("resize", appHeight);
    };
  });

  if (contains === true) {
    return (
      <>
        <Script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5NQ9C2Q');`,
          }}
        />
        {firstLoad ? (
          <Loader location={locationPath} active={firstLoad} />
        ) : (
          <Loader location={locationPath} active={firstLoad} />
        )}
        <Notice />
        <Footer location={locationPath} />
        <Header location={location} windowHeight={windowHeight} />
        <main ref={wrapperRef} className={styles.main}>
          {children}
        </main>
        <Background />
      </>
    );
  } else {
    return (
      <>
        <Script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5NQ9C2Q');`,
          }}
        />
        <Helmet title="Flannels Digital Map" defer={false} />
        <MissingPage />
      </>
    );
  }
};

export default Layout;
