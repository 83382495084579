import * as React from "react";
import { forwardRef, useContext } from "react";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/globalContextProvider";
import Navigation from "./navigation";
import * as styles from "./header.module.scss";

const Header = (props, ref) => {
  if (props.location.pathname.split("/")[1] === "liverpool") {
    var logo = "/logo-liverpool.svg";
  } else if (props.location.pathname.split("/")[1] === "leeds") {
    var logo = "/logo-leeds.svg";
  }

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  return (
    <>
      <Navigation
        location={props.location}
        data={state.navigation}
        windowHeight={props.windowHeight}
        open={state.navigation}
      />

      <header className={styles.container} ref={ref}>
        <div className={styles.logo}>
          <img src={logo} alt="Sports Direct Map" />
          <button
            className={styles.btn}
            type="button"
            onClick={() => {
              dispatch({ type: "TOGGLE_NAV" });
            }}
          >
            {state.navigation ? "Close" : "List view"}
          </button>
        </div>
      </header>
    </>
  );
};

export default forwardRef(Header);
