import * as React from "react"
import * as styles from "./background.module.scss"

const Background = () => {
  return (

    <div className={styles.background}></div>

  )
}

export default Background
