// extracted by mini-css-extract-plugin
export var active = "navigation-module--active--5964e";
export var bacino = "navigation-module--bacino--065c4";
export var block = "navigation-module--block--0709e";
export var container = "navigation-module--container--a923d";
export var floors = "navigation-module--floors--47a61";
export var floorscolumn = "navigation-module--floorscolumn--34e4c";
export var heading = "navigation-module--heading--2e7bf";
export var key = "navigation-module--key--5bbe1";
export var keyblock = "navigation-module--keyblock--6b0d9";
export var link = "navigation-module--link--303ae";
export var list = "navigation-module--list--01bfe";
export var listcolumn = "navigation-module--listcolumn--2d7ed";
export var logolist = "navigation-module--logolist--1066b";
export var sublist = "navigation-module--sublist--852f0";