import * as React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  navigation: false,
  footer: false,
  load: false
}

function reducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_NAV': {
      return {
        ...state,
        navigation: state.navigation === false ? true : false
      }
    }
    case 'TOGGLE_FOOTER': {
      return {
        ...state,
        footer: action.payload
      }
    }
    case 'TOGGLE_LOAD': {
      return {
        ...state,
        load: action.payload
      }
    }
    default:
      throw new Error('Bad action type')
  }
}

// markup
const GlobalContextProvider = ({ children }) => {

  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (

    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>

  )
}

export default GlobalContextProvider
