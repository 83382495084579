import * as React from "react";
import { useRef, useEffect, useContext } from "react";
import { GlobalStateContext } from "../context/globalContextProvider";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import * as styles from "./footer.module.scss";

const Footer = (props) => {
  const state = useContext(GlobalStateContext);
  const navigationRef = useRef();
  const footerRef = useRef();

  function setPageID() {
    const links = navigationRef.current.getElementsByTagName("a");
    const footer = footerRef.current;

    for (var i = 0; i < links.length; i++) {
      if (links[i].hasAttribute("class")) {
        if (links[i].getAttribute("class").includes("active")) {
          var id = links[i].getAttribute("data-id");
          footer.setAttribute("data-page", id);
        }
      }
    }
  }

  useEffect(() => {
    setPageID();
  });

  return (
    <>
      <div className={`${styles.pinch} ${state.footer ? "" : styles.active}`}>
        <p>Pinch to zoom</p>
      </div>

      <footer
        ref={footerRef}
        className={`${styles.footer} ${state.footer ? "" : styles.active}`}
      >
        <ul ref={navigationRef}>
          <li>
            <AniLink
              onClick={setPageID}
              fade
              activeClassName={styles.active}
              partiallyActive={true}
              to={"/" + props.location + "/basement"}
              data-id="1"
            >
              B
            </AniLink>
          </li>
          <li>
            <AniLink
              onClick={setPageID}
              fade
              activeClassName={styles.active}
              partiallyActive={false}
              to={"/" + props.location + "/"}
              data-id="2"
            >
              G
            </AniLink>
          </li>
          <li>
            <AniLink
              onClick={setPageID}
              fade
              activeClassName={styles.active}
              partiallyActive={true}
              to={"/" + props.location + "/level-1"}
              data-id="3"
            >
              1
            </AniLink>
          </li>
          <li>
            <AniLink
              onClick={setPageID}
              fade
              activeClassName={styles.active}
              partiallyActive={true}
              to={"/" + props.location + "/level-2"}
              data-id="4"
            >
              2
            </AniLink>
          </li>
          <li>
            <AniLink
              onClick={setPageID}
              fade
              activeClassName={styles.active}
              partiallyActive={true}
              to={"/" + props.location + "/level-3"}
              data-id="5"
            >
              3
            </AniLink>
          </li>
          <li>
            <AniLink
              onClick={setPageID}
              fade
              activeClassName={styles.active}
              partiallyActive={true}
              to={"/" + props.location + "/level-4"}
              data-id="6"
            >
              4
            </AniLink>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default Footer;
