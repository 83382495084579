import * as React from "react"
import * as styles from "./notice.module.scss"

const Notice = ( props ) => {

  return (

    <div className={styles.notice}>
      <div className={styles.container}>
        <svg width="254" height="512" viewBox="0 0 254 512" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M191 16H216C228.15 16 238 25.8496 238 38V474C238 486.15 228.15 496 216 496H38C25.8496 496 16 486.15 16 474V38C16 25.8496 25.8496 16 38 16H64C65.1045 16 66 16.8955 66 18V22C66 28.627 71.3726 34 78 34H177C183.627 34 189 28.627 189 22V18C189 16.8955 189.896 16 191 16ZM0 38C0 17.0127 17.0132 0 38 0H216C236.987 0 254 17.0127 254 38V474C254 494.986 236.987 512 216 512H38C17.0132 512 0 494.986 0 474V38Z" fill="white"/>
        </svg>
        <h1>This experience works best on mobile devices</h1>
        <p>Please try again on a smaller device or resize your browser window</p>
      </div>
    </div>

  )
}

export default Notice
