import * as React from "react"
import * as styles from "./404.module.scss"

const MissingPage = () => {

  return (

    <div className={styles.notice}>
      <div className={styles.container}>
        <h1>Page not found</h1>
        <p>We couldn’t find what you were looking for.</p>
      </div>
    </div>

  )
}

export default MissingPage
